import { mapActions } from 'vuex';
import { $EventBus } from '@/main';

export default {
	name: 'updateSettingDialog',
	props: {},
	data() {
		return {
			dialog: false,
			valid: true,
			days: 0,
			loading: false,
			loadingSave: false,
			numberRules: [
				(v) => !!v || 'Campo requerido',
				(v) => /^\d*$/.test(v) || 'Campo numérico',
				// (v) => v > 0 || 'El valor debe ser mayor que cero',
			],
		};
	},
	created() {},
	mounted() {},
	computed: {},
	watch: {
		dialog(newVal) {
			if (newVal) {
				this.getNotificationSettings();
			}
		},
	},
	methods: {
		...mapActions('agreementNotification', [
			'readNotificationSettings',
			'updateNotificationSettings',
		]),

		async sendApprove() {
			const isvalidated = this.$refs.userForm.validate();
			if (isvalidated) {
				this.loadingSave = true;
				const data = { value: this.days };
				const { ok, response, error } = await this.updateNotificationSettings(
					data
				);
				if (ok) {
					this.showAlert(response?.message, 'success');
					this.closeDialog();
				} else {
					this.showAlert(error?.data?.message);
				}
				this.loadingSave = false;
			}
		},

		showAlert(msmError, color = 'error') {
			msmError = Array.isArray(msmError) ? msmError.join('<br> ') : msmError;
			$EventBus.$emit('showSnack', color, msmError);
		},

		closeDialog() {
			this.$nextTick(() => {
				if (this.$refs?.userForm) {
					this.$refs.userForm.resetValidation();
				}
			});
			this.dialog = false;
		},

		async getNotificationSettings() {
			this.loading = true;
			const { ok, response, error } = await this.readNotificationSettings();
			if (ok) {
				const settings = Array.isArray(response.agreementSettings)
					? response.agreementSettings
					: [];
				this.days = settings[0]?.value || 0;
			} else {
				this.showAlert(error?.data?.message || 'Error');
			}
			this.loading = false;
		},
	},
	components: {},
};
