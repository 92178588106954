<template>
	<v-container>
		<v-row justify="space-between" align="center" dense>
			<v-col cols="auto">
				<v-breadcrumbs :items="breadcrumbs" class="mb-4">
					<template v-slot:divider>
						<v-icon>mdi-chevron-right</v-icon>
					</template>
				</v-breadcrumbs>
			</v-col>
			<v-col cols="auto">
				<update-setting-dialog />
			</v-col>
		</v-row>
		<agreement-notification />
	</v-container>
</template>
<script>
import AgreementNotification from '@/components/agreement/notification/list/AgreementNotification.vue';
import updateSettingDialog from '@/components/agreement/notification/updateSetting/updateSettingDialog.vue';
export default {
	name: 'AgreementNotificationView',
	data() {
		return {
			breadcrumbs: [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Abastecimiento',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'AgreementHome',
					},
				},
				{
					text: 'Notificaciones',
					disabled: true,
					href: '/',
				},
			],
		};
	},
	components: { AgreementNotification, updateSettingDialog },
};
</script>
